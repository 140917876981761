import { Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConflictResolutionModalParams } from './ConflictResolutionModalParams';

@Component({
  selector: 'app-conflict-resolution-modal',
  templateUrl: './conflict-resolution-modal.component.html',
  styleUrls: ['./conflict-resolution-modal.component.less'],
})
export class ConflictResolutionModalComponent {
  showObjectMessage: boolean;
  customMessage: string;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<ConflictResolutionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public params: ConflictResolutionModalParams,
    private translate: TranslateService
  ) {}

  @HostListener('window:keydown.enter')
  onConfirm(): void {
    this.dialogRef.close(true);
  }

  @HostListener('window:keydown.escape')
  onCancel(): void {
    this.dialogRef.close(false);
  }
}
