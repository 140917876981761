<svg viewBox="0 0 24 24" [ngClass]="disableAutoColor ? '' : 'icon-' + ref">
  <defs>
    <filter [id]="id">
      <feColorMatrix
        in="black"
        type="matrix"
        values=" 0  0  0  0  1
                 0  0  0  0  1
                 0  0  0  0  1
                 0  0  0  1  0"
        result="white"
      />
      <feFlood result="flood" flood-color="currentColor" />
      <feComposite
        in="white"
        in2="flood"
        operator="arithmetic"
        k1="1"
        k2="0"
        k3="0"
        k4="0"
      />
    </filter>
  </defs>

  <mask id="crossMask">
    <use xlink:href="assets/icons/background-cross4.svg#root"></use>
  </mask>
  <use
    [attr.xlink:href]="'assets/icons/' + ref + '.svg#root'"
    [attr.mask]="mask"
    [attr.filter]="filter"
  ></use>

  <use
    *ngIf="cross"
    xlink:href="assets/icons/add-cross.svg#root"
    x="0.75"
    y="0.1"
  ></use>
</svg>
