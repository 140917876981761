import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  HostListener,
  OnInit,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigurationService } from './services/configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LANGUAGE, LANGUAGES } from './utils/cst';
import { EventService } from './services/events.service';

declare var Zone: any;
declare var event: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit, DoCheck {
  constructor(
    private spinner: NgxSpinnerService,
    private configurationService: ConfigurationService,
    private eventService: EventService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService
  ) {}

  lastKeydown: string;

  @HostListener('window:keydown', ['$event']) onAltDown($event: KeyboardEvent) {
    if ($event.key === 'Alt') {
      this.eventService.setAltDown();
    } else if ($event.key === 'Control' || $event.key === 'Meta') {
      this.eventService.setCtrlDown();
    } else if ($event.key === 'Shift') {
      this.eventService.setShiftDown();
    }
  }

  @HostListener('window:keyup', ['$event']) onAltUp($event: KeyboardEvent) {
    if ($event.key === 'Alt') {
      this.eventService.setAltUp();
    } else if ($event.key === 'Control' || $event.key === 'Meta') {
      this.eventService.setCtrlUp();
    } else if ($event.key === 'Shift') {
      this.eventService.setShiftUp();
    } else if ($event.key === 'F2') {
      this.eventService.keyF2Pressed$.next();
    }
  }

  @HostListener('window:blur', ['$event']) onBlur($event) {
    this.eventService.setAltUp();
    this.eventService.setCtrlUp();
    this.eventService.setShiftUp();
  }

  ngOnInit() {
    this.configurationService.setSpinner(this.spinner);
    this.translate.addLangs(LANGUAGES.map((l) => l.id));
    this.translate.setDefaultLang(DEFAULT_LANGUAGE);

    // Disable automatic change detection
    // We will trigger change detection manually via cd.detectChanges() in ngDoCheck()
    // This way we can try to throttle Angular's heavy handed change detection
    this.cd.detach();
  }

  ngDoCheck(): void {
    // Keydown event is subject to repeating fire (by holding down the key), so we prevent change detection after the first keydown
    if (Zone.currentTask.source.endsWith('keydown')) {
      if (this.lastKeydown === event.key) {
        // && (event.key === 'Alt' || event.key === 'Control' || event.key === 'Meta')) {
        return;
      }
      // Store last keydown event to detect repeating fire
      this.lastKeydown = event.key;

      // We want to wait until all callbacks have been executed before triggering change detection.
      // Currently, we only need this for keydown events.
      // If there is ever a change detection problem with other types of events,
      // the same approach could be used, however it can also cause issues on its own.
      setTimeout(() => this.cd.detectChanges());

      return;
    } else {
      if (Zone.currentTask.source.endsWith('keyup')) {
        this.lastKeydown = undefined;
      }
      this.cd.detectChanges();
    }
  }
}
