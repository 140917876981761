import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DEFAULT_LANGUAGE, SAVE_LANG } from '../utils/cst';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LangService {
  public current: BehaviorSubject<string> = new BehaviorSubject<string>(
    DEFAULT_LANGUAGE
  );

  constructor(private httpClient: HttpClient) {}

  // *****************************************************************************************************************
  // REQUESTS FOR AUTHENTICATION
  // *****************************************************************************************************************

  update(lang: string, save: boolean) {
    this.current.next(lang);
    if (save) {
      this.httpClient.post<any>(SAVE_LANG, lang).subscribe();
    }
  }
}
