import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from '../views/alert/alert.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { TreeModule } from '@ali-hm/angular-tree-component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AutoSizeInputModule } from 'ngx-autosize-input';
import { IconComponent } from '../views/icon/icon.component';

@NgModule({
  declarations: [AlertComponent, IconComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    AutoSizeInputModule,
    TreeModule,
  ],
  exports: [
    AlertComponent,
    IconComponent,
    MatIconModule,
    MatTooltipModule,
    AutoSizeInputModule,
    TreeModule,
    MatButtonModule,
    NgxSpinnerModule,
  ],
})
export class SharedModule {}
