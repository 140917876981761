<h1 class="mat-dialog-title">
  {{ "services.conflict" | translate }}
</h1>

<div class="mat-dialog-content">
  <div>{{ "services.suggestion-1" | translate }}</div>
  <div class="ml-2 mb-2">
    {{ params.suggestionResult.desiredCommit.formQualifiedLabel }} =
    {{ params.suggestionResult.desiredCommit.valueLabel }}
  </div>
  <div>{{ "services.suggestion-2" | translate }}</div>
  <div
    *ngFor="let suggestion of params.suggestionResult.suggestions"
    class="ml-2"
  >
    - {{ suggestion.formQualifiedLabel }} :
    {{ suggestion.previousValueLabel }} → {{ suggestion.valueLabel }}
  </div>
</div>
<div class="flex mat-dialog-actions mt-2">
  <button
    id="cancel-button"
    class="btn btn-outline-secondary"
    (click)="onCancel()"
  >
    {{ "cancel" | translate }}
  </button>
  <button id="confirm-button" class="btn btn-info" (click)="onConfirm()">
    {{ "confirm" | translate }}
  </button>
</div>
