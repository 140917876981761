import { SortDirectionNumber, Column } from 'angular-slickgrid';

export const FILE_FORMAT_EXCEL =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
export const FILE_FORMAT_IMAGE = 'image/png, image/jpg, image/jpeg';
export const FILE_FORMAT_PDF = 'application/pdf';
export const FILE_FORMAT_CSV = '.csv';
export const FILE_FORMAT_JSON = 'application/JSON';

export const ARRAY_SEPARATOR = ';';
export const RANGE_SEPARATOR = '..';
