import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UPLOAD, IMPORT_MODEL, CONVERT_XML, IMPORT_FOLDER } from '../utils/cst';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private httpClient: HttpClient) {}

  public uploadImage(formData) {
    return this.httpClient.post<any>(UPLOAD, formData);
  }

  public deleteImage(completeURL: string) {
    return this.httpClient.delete<any>(completeURL);
  }

  public importFolder(data) {
    return this.httpClient.post<any>(IMPORT_FOLDER, data);
  }

  public importJSON(file: any) {
    return this.httpClient.post<any>(IMPORT_MODEL, file);
  }

  public convertXMLToFiles(file: any) {
    return this.httpClient.post<any>(CONVERT_XML, file);
  }
}
