import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { WorkspaceNode } from '../views/user/classes/workspace-node';
import { ElementType } from '../utils/cst';

@Injectable({
  providedIn: 'root',
})
export class BreadCrumbService {
  currentFile$: Subject<WorkspaceNode> = new Subject<WorkspaceNode>();
  currentProject$: Subject<{ name: string; id: string }> = new Subject<{
    id: string;
    name: string;
  }>();
  fileFocus$: Subject<string> = new Subject<string>();
  type$: Subject<ElementType> = new Subject<ElementType>();

  constructor() {}
}
