<div class="login-form">
  <div class="mat-dialog-title">
    {{ "authentification.re-login.expired" | translate }}
  </div>

  <div class="mat-dialog-content mb-3">
    <div class="col-md-auto m-2">
      <input
        [(ngModel)]="mail"
        #email
        type="email"
        placeholder="{{ 'authentification.email.placeholder' | translate }}"
        class="form-control"
        [disabled]="isMailSaved"
      />
    </div>
    <div class="col-md-auto m-2">
      <input
        [(ngModel)]="pass"
        #password
        type="password"
        placeholder="{{ 'authentification.password.placeholder' | translate }}"
        class="form-control"
        (keydown.enter)="onAuth()"
      />
    </div>
  </div>

  <div class="flex mat-dialog-actions">
    <button
      id="cancel-button"
      class="btn btn-outline-secondary"
      (click)="onCancel()"
    >
      {{ "authentification.re-login.cancel" | translate }}
    </button>
    <button id="confirm-button" class="btn btn-primary" (click)="onAuth()">
      {{ "authentification.re-login.connect" | translate }}
    </button>
  </div>
</div>
