import { FormControl, FormGroup } from '@angular/forms';
import { Editors } from 'angular-slickgrid';
import { Observable, of } from 'rxjs';
import { RESERVED_WORDS } from './cst';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ARRAY_SEPARATOR, RANGE_SEPARATOR } from '../cg-utils/others/cst';

export class UniqueTextEditor extends Editors.text {
  backup;

  applyValue(item: any, value: any) {
    const val = value.trim();
    if (val === this.backup) {
      return;
    } else if (
      this.grid
        .getData()
        .getItems()
        .find((ele) => ele[this.columnDef.field].toString() === val)
    ) {
      this.columnDef.params.unicityWarning();
      return;
    } else if (val === '') {
      this.columnDef.params.emptyWarning();
      return;
    }
    item[this.columnDef.field] = value.trim();
  }

  loadValue(item: any): void {
    this.backup = item[this.columnDef.field];
    super.loadValue(item);
  }
}

export function passwordMatchValidator(g: FormGroup) {
  return g.get('password').value === g.get('passwordConfirm').value
    ? null
    : { mismatch: true };
}

export function attributeNameValidator(f: FormControl) {
  return f.value === 'id' || f.value === '$REFERENCE' || f.value === '$INDEX'
    ? { id: true }
    : null;
}

function isOutOfBounds(num: number): boolean {
  return num > 2147483646 || num < -2147483647;
}

export function isValidNumber(input: string, forbidFloats = false) {
  const n = +input;
  return !isNaN(n) && !isOutOfBounds(n) && (!forbidFloats || n % 1 === 0);
}

/* check if the attribute domain is valid */
export function isValidNumericDomain(attributeDomain, forbidFloats = false) {
  if (attributeDomain === 'int') {
    return true;
  }
  if (attributeDomain === 'float' && !forbidFloats) {
    return true;
  }

  const domains: Array<string> = Array.isArray(attributeDomain)
    ? attributeDomain
    : attributeDomain.split(ARRAY_SEPARATOR).map((ele) => ele.trim());
  for (const domain of domains) {
    const val = domain.split(RANGE_SEPARATOR); // in case of range value, like "1..10"
    if (
      val.length === 2 &&
      (!isValidNumber(val[0], forbidFloats) ||
        !isValidNumber(val[1], forbidFloats))
    ) {
      return false;
    } else if (val.length === 1 && !isValidNumber(val[0], forbidFloats)) {
      return false;
    } else if (val.length > 2) {
      return false;
    }
  }
  return true;
}

export function checkInput(input, existingNames): string {
  if (input === '') {
    return 'user.input.empty-name';
  }
  if (isNameInList(input, RESERVED_WORDS)) {
    return 'user.input.reserved-name';
  }
  if (isNameInList(input, existingNames)) {
    return 'user.input.same-name';
  }
  return null;
}

export function findUnusedName(
  prefix: string,
  nameList,
  compare = (a, b) => a === b
): string {
  let name = prefix;
  let n = 1;
  while (nameList.find((c) => compare(c, name)) !== undefined) {
    name = prefix + ' (' + n + ')';
    n++;
  }
  return name;
}

export function findUnusedValue(valuesList): number {
  let valCounter = 1; // we'll use it to generate a value which is not present
  for (const dataLine of valuesList) {
    if (!isNaN(dataLine.value) && dataLine.value >= valCounter) {
      valCounter = +dataLine.value + 1;
    }
  }
  return valCounter;
}

export function isNameInList(input, nameList) {
  let inList = false;
  nameList.forEach((word) => {
    inList = inList || word?.trim() === input;
  });
  return inList;
}

export function isUUID(value): boolean {
  const regexExp =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(value);
}

export function renameModeOff(el) {
  el.setAttribute('contenteditable', 'false');
  el.setAttribute('draggable', 'false');
  el.blur();
}

export function renameModeOn(el) {
  if (el.getAttribute('contenteditable') !== 'true') {
    el.setAttribute('contenteditable', 'true');
    el.setAttribute('draggable', 'true');
    el.focus();
    window.getSelection().selectAllChildren(el); // Select the text inside the box
  }
}

export function propagateClassesToDropDown(classes: string): void {
  if (classes.length === 0) {
    return;
  }

  setTimeout(() => {
    const panel = document.querySelector('.ng-dropdown-panel');
    panel.classList.add(classes);
  }, 0);
}

export function avoidSelectingGroups(select) {
  setTimeout(() => {
    const items = select.itemsList;
    if (
      items.filteredItems.length > 0 &&
      items.filteredItems[items.markedIndex].children
    ) {
      if (items.markedIndex === 0) {
        items.markNextItem();
      } else {
        items.markPreviousItem();
      }
      select.detectChanges();
    }
  });
}

export function isElementVisible(el) {
  const rect = el.getBoundingClientRect();
  const vWidth = window.innerWidth || document.documentElement.clientWidth;
  const vHeight = window.innerHeight || document.documentElement.clientHeight;

  // Return false if it's not in the viewport
  return !(
    rect.right < 0 ||
    rect.bottom < 0 ||
    rect.left > vWidth ||
    rect.top > vHeight
  );
}

export function convertIndexArrayIntoIdArray(
  rowsIndexToDelete: number[],
  dataset: Array<any>
): string[] {
  const rowsIdToDelete = [];
  for (const rowIndex of rowsIndexToDelete) {
    const row = dataset.find((value, index) => index === rowIndex);
    rowsIdToDelete.push(row.id);
  }
  return rowsIdToDelete;
}

export function refreshIndexes(dataset: Array<any>): Array<any> {
  dataset.forEach((row, index) => {
    row._index = index + 1;
  });
  return dataset;
}

export function shortenLongString(name: string, size) {
  if (name.length > size) {
    name = name.substring(0, size) + '...';
    return name;
  }
  return name;
}

export function closeMenuIfLeave(event, trigger) {
  // toElement doesn't exist on Mozilla
  const target = event.toElement ? event.toElement : event.relatedTarget;
  if (
    target == null ||
    (trigger._element.nativeElement.parentNode !== target &&
      !Array.from(target.classList).includes('mat-menu-panel') &&
      !Array.from(target.classList).includes('mat-menu-item') &&
      !Array.from(target.classList).includes('mat-menu-wrapper') &&
      !Array.from(target.classList).includes('cdk-overlay-pane'))
  ) {
    trigger.closeMenu();
  }

  if (Array.from(target.classList).includes('cdk-overlay-pane')) {
    target.addEventListener('mouseleave', (e) =>
      this.closeMenuIfLeave(e, trigger)
    );
  }
}

export function withSpinner$<T>(
  obs: Observable<T>,
  spinner: NgxSpinnerService
): Observable<T> {
  return of(null).pipe(
    tap(() => spinner.show()),
    switchMap(() => obs),
    finalize(() => spinner.hide())
  );
}
