import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnChanges {
  @Input() ref;
  @Input() cross;
  @Input() disableAutoColor;
  @Input() noFilter;

  mask;
  id = Math.round(Math.random() * 1000000000);
  filter;
  insertSrc;

  ngOnChanges(changes: SimpleChanges): void {
    this.filter = this.noFilter ? undefined : 'url(#' + this.id + ')';
    this.mask = this.cross ? 'url(#crossMask)' : '';
  }
}
