import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, switchMap, take, tap } from 'rxjs/operators';
import {
  INIT_CONFIG,
  CONFIGURATION_UPLOAD_MODEL,
  CONFIGURATION_SCENARIOS,
  CONFIGURATION_CONFLICT_SUGGESTIONS,
  CONFIGURATION_MULTI_COMMIT_URL,
} from '../utils/cst';
import { ConflictResolutionModalComponent } from '../views/conflict-resolution-modal/conflict-resolution-modal.component';
import {
  CONFIGURATION_APPLY_SCENARIO,
  CONFIGURATION_CLEAR_INVALID_COMMITS,
  CONFIGURATION_COMMIT_URL,
  CONFIGURATION_DELETE_SCENARIO,
  CONFIGURATION_DOMAINS_URL,
  CONFIGURATION_UNCOMMIT_URL,
  CONFIGURATION_NEXT_URL,
  CONFIGURATION_OVERWRITE_SCENARIO,
  CONFIGURATION_PREVIOUS_URL,
  CONFIGURATION_SAVE_SCENARIO,
  CONFIGURATION_CLEAR_ALL_COMMITS,
} from '../utils/cst';
import { withSpinner$ } from 'src/app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  // *****************************************************************************************************************
  // CONSTRUCTOR / ATTRIBUTES
  // *****************************************************************************************************************

  constructor(
    private httpClient: HttpClient,
    public toaster: ToastrService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {}

  public update$: BehaviorSubject<any> = new BehaviorSubject(null);
  public scenarios$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public error$: Subject<void> = new Subject();

  private spinner: NgxSpinnerService;

  // *****************************************************************************************************************
  // PUBLIC METHODS
  // *****************************************************************************************************************

  reset() {
    this.update$ = new BehaviorSubject(null);
    this.scenarios$ = new BehaviorSubject([]);
    this.error$ = new Subject();
  }

  setSpinner(spinner: NgxSpinnerService) {
    this.spinner = spinner;
  }

  public initConfig(id: string) {
    this.spinner.show();
    return this.retrieveDomainsAfter(
      this.httpClient
        .post<any>(INIT_CONFIG, id)
        .pipe(switchMap(() => this.getCommitScenarios()))
    );
  }

  uploadFileParseForm(model: FormData) {
    this.spinner.show();
    this.retrieveDomainsAfter(
      this.httpClient.post<any>(CONFIGURATION_UPLOAD_MODEL, model)
    ).subscribe();
  }

  commitField(field: any) {
    this.spinner.show();
    return this.httpClient
      .post<any>(CONFIGURATION_COMMIT_URL, { ...field })
      .pipe(
        // {} transformed into CCCommitDTO object
        tap((ok) => this.update$.next(ok)),
        catchError((commitError) => {
          const commitWrappedError = commitError.error?.error;
          if (commitWrappedError?.code !== 'SOLVER_CONFLICT') {
            // true error
            this.error$.next();
            this.toaster.error(
              commitWrappedError.localizedMessage +
                (commitWrappedError.path
                  ? ' (' + commitWrappedError.path + ')'
                  : '')
            );
            this.spinner.hide();
            return EMPTY;
          } else {
            // conflict
            return this.handleConflict(field);
          }
        }),
        finalize(() => this.spinner.hide())
      );
  }

  unCommitField(path: string[]) {
    this.spinner.show().then(() =>
      this.httpClient
        .post<any>(CONFIGURATION_UNCOMMIT_URL, { path })
        .pipe(
          tap((ok: any) => {
            this.update$.next(ok);
          }),
          take(1),
          finalize(() => this.spinner.hide())
        )
        .subscribe()
    );
  }

  public clearAllCommits() {
    const pipe$ = this.httpClient
      .post<any>(CONFIGURATION_CLEAR_ALL_COMMITS, null)
      .pipe(
        tap((ok: any) => {
          this.update$.next(ok);
        })
      );
    withSpinner$(pipe$, this.spinner).subscribe();
  }

  public clearInvalidCommits() {
    this.httpClient
      .post<any>(CONFIGURATION_CLEAR_INVALID_COMMITS, null)
      .pipe(
        tap((ok: any) => {
          this.update$.next(ok);
        }),
        take(1),
        finalize(() => this.spinner.hide())
      )
      .subscribe();
  }

  next() {
    this.spinner.show();
    this.httpClient
      .post<any>(CONFIGURATION_NEXT_URL, null)
      .pipe(
        tap(
          (ok) => this.update$.next(ok),
          (resultKO) => this.logError(resultKO)
        ),
        finalize(() => this.spinner.hide())
      )
      .subscribe();
  }

  previous() {
    this.spinner.show();
    this.httpClient
      .post<any>(CONFIGURATION_PREVIOUS_URL, null)
      .pipe(
        tap(
          (ok) => this.update$.next(ok),
          (resultKO) => this.logError(resultKO)
        ),
        finalize(() => this.spinner.hide())
      )
      .subscribe();
  }

  public createScenario(scenarioName) {
    return this.httpClient
      .post<any>(CONFIGURATION_SAVE_SCENARIO, null, {
        params: { name: scenarioName },
      })
      .pipe(switchMap((ok) => this.getCommitScenarios()));
  }

  public overwriteScenario(id): Observable<any> {
    return this.httpClient.post<any>(CONFIGURATION_OVERWRITE_SCENARIO, null, {
      params: { id },
    });
  }

  private getCommitScenarios(): Observable<any> {
    return this.httpClient.get<any>(CONFIGURATION_SCENARIOS).pipe(
      tap((result) => {
        const scenarioById = new Map<string, any>();
        this.scenarios$.value.forEach((s) => scenarioById.set(s.id, s));
        this.scenarios$.next(result.map((s) => scenarioById.get(s.id) || s));
      })
    );
  }

  public deleteScenario(id): Observable<any> {
    return this.httpClient
      .post<any>(CONFIGURATION_DELETE_SCENARIO, null, { params: { id } })
      .pipe(switchMap((ok) => this.getCommitScenarios()));
  }

  public applyCommitScenario(id): Observable<any> {
    this.spinner.show();
    return this.httpClient
      .post<any>(CONFIGURATION_APPLY_SCENARIO, null, { params: { id } })
      .pipe(
        tap(
          (ok: any) => {
            this.update$.next(ok);
            this.scenarios$.next(
              this.scenarios$.value.map((s) => ({ ...s, applied: s.id === id }))
            );

            if (
              ok.commits.filter((c) => c.invalid || c.deletedTarget).length > 0
            ) {
              this.toaster.warning(
                this.translate.instant(
                  'user.configurations.applied-scenario-invalid-commits'
                )
              );
              throw throwError(ok);
            } else {
              this.toaster.success(
                this.translate.instant('user.configurations.applied-scenario')
              );
            }
          },
          (ko: any) => {
            this.update$.next(ko);
          }
        ),
        take(1),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  // *****************************************************************************************************************
  // PRIVATE METHODS
  // *****************************************************************************************************************

  private retrieveDomainsAfter(previousAction: Observable<any>) {
    return previousAction.pipe(
      switchMap((ok) => this.httpClient.get<any>(CONFIGURATION_DOMAINS_URL)),
      tap((ok) => this.update$.next(ok)),
      catchError((e) => {
        this.logError(e);
        return throwError(e);
      }),
      finalize(() => this.spinner.hide())
    );
  }

  private logError(result: any) {
    if (result && result.error && result.error.error) {
      const error = result.error.error;
      this.toaster.error(
        error.message +
          (error.path
            ? ' ' +
              this.translate.instant('services.config-at') +
              ' ' +
              error.path
            : ''),
        null,
        {
          timeOut: 8000,
          extendedTimeOut: 4000,
        }
      );
    } else {
      this.toaster.error(JSON.stringify(result.message));
    }
  }

  private handleConflict(field: {
    path: any;
    value: any;
    valueLB: any;
    valueUB: any;
  }): Observable<any> {
    return this.httpClient
      .post<any>(CONFIGURATION_CONFLICT_SUGGESTIONS, { ...field })
      .pipe(
        finalize(() => {
          this.spinner.hide();
          this.error$.next();
        }),
        tap((suggestionResult) => {
          try {
            if (suggestionResult.suggestions.length > 0) {
              this.openResolutionModal(field, suggestionResult);
            } else {
              this.toaster.error(this.translate.instant('services.invalid'));
            }
          } catch (e) {
            this.toaster.error(this.translate.instant('services.invalid'));
          }
        }),
        catchError((err) => {
          this.toaster.error(this.translate.instant('services.invalid'));
          return EMPTY;
        })
      );
  }

  private openResolutionModal(
    field: { path: any; value: any; valueLB: any; valueUB: any },
    suggestionResult: any
  ) {
    const modalRef = this.dialog.open(ConflictResolutionModalComponent, {
      panelClass: 'wide-mat-modal',
      data: {
        suggestionResult,
      },
    });
    modalRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.spinner.show();
        this.httpClient
          .post<any>(CONFIGURATION_MULTI_COMMIT_URL, [
            ...suggestionResult.suggestions,
            { ...field },
          ])
          .pipe(
            tap((ok: any) => {
              this.update$.next(ok);
            }),
            take(1),
            catchError((e) => {
              this.toaster.error(
                this.translate.instant('services.resolution-error')
              );
              return throwError(e);
            }),
            finalize(() => this.spinner.hide())
          )
          .subscribe();
      }
    });
  }
}
