import { Component } from '@angular/core';

declare var $: any;
const DURATION_DEFAULT = 2000;

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.less'],
})
export class AlertComponent {
  constructor() {}

  alertDanger(message, duration = DURATION_DEFAULT) {
    // show the alert component
    $('#alert-danger').fadeIn(200).slideDown(300);
    // set the message
    $('#alert-danger').text(message);
    // hide the alert component after the duration
    $('#alert-danger').delay(duration).fadeOut(200);
  }

  alertSuccess(message, duration = DURATION_DEFAULT) {
    // show the alert component
    $('#alert-success').fadeIn(200).slideDown(300);
    // set the message
    $('#alert-success').text(message);
    // hide the alert component after the duration
    $('#alert-success').delay(duration).fadeOut(200);
  }
}
